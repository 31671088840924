var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-9 ms-sm-auto col-lg-10 px-md-4"},[_c('main',[_c('div',{staticClass:"main_content align-items-center"},[_c('div',{staticClass:"row justify-content-between mb_40"},[_c('div',{staticClass:"row justify-content-between mb_40 updateclient"},[_vm._m(0),_c('div',{staticClass:"col-xl-12 col-xxl-6"},[_c('div',{staticClass:"alche_btn tab_report toggle_switch list_card_switch me-0 my-2 d-flex justify-content-sm-start justify-content-md-end"},[_c('ul',{staticClass:"nav nav-tabs list_tabs btn-reports",attrs:{"id":"myTab","role":"tablist"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index,staticClass:"nav-item list-item",attrs:{"role":"presentation"}},[_c('button',{class:[
                      'nav-link list-link',
                      { active: _vm.activeTab === tab.id },
                    ],attrs:{"id":`${tab.id}-tab`,"disabled":_vm.activeTab === tab.id,"data-bs-toggle":'tab',"data-bs-target":`#${tab.id}`,"type":"button","role":"tab","aria-controls":tab.id,"aria-selected":_vm.activeTab === tab.id},on:{"click":function($event){return _vm.handleTabClick(tab)}}},[_vm._v(" "+_vm._s(tab.label)+" ")])])}),0)])])]),_c('form',{attrs:{"method":"post","data-vv-scope":"updateClientForm"},on:{"submit":function($event){$event.preventDefault();return _vm.updateClientErgoEval.apply(null, arguments)}}},[_c('div',{staticClass:"card site_footer mb_24 editor_section"},[_vm._m(1),_c('div',{staticClass:"row client_accordian"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Lab Equipment Heading")]),_c('ckeditor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"editor":_vm.editor,"data-vv-as":"lab equipment heading","config":_vm.editorConfig,"name":"ergo_lab_equipment_heading","id":"ergo_lab_equipment_heading"},on:{"blur":function($event){return _vm.onLostFocusEditor(
                        'updateClientForm.ergo_lab_equipment_heading'
                      )}},model:{value:(_vm.client_data.ergo_lab_equipment_heading),callback:function ($$v) {_vm.$set(_vm.client_data, "ergo_lab_equipment_heading", $$v)},expression:"client_data.ergo_lab_equipment_heading"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.errors.has(
                        'updateClientForm.ergo_lab_equipment_heading'
                      )
                    ),expression:"\n                      errors.has(\n                        'updateClientForm.ergo_lab_equipment_heading'\n                      )\n                    "}],staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "updateClientForm.ergo_lab_equipment_heading" ))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("WFH Safety Message")]),_c('ckeditor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"editor":_vm.editor,"data-vv-as":"wfh saftey message","config":_vm.editorConfig,"name":"ergo_lab_wfh_saftey_message","id":"ergo_lab_wfh_saftey_message"},on:{"blur":function($event){return _vm.onLostFocusEditor(
                        'updateClientForm.ergo_lab_wfh_saftey_message'
                      )}},model:{value:(_vm.client_data.ergo_lab_wfh_saftey_message),callback:function ($$v) {_vm.$set(_vm.client_data, "ergo_lab_wfh_saftey_message", $$v)},expression:"client_data.ergo_lab_wfh_saftey_message"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.errors.has('updateClientForm.ergo_lab_wfh_saftey_message')
                    ),expression:"\n                      errors.has('updateClientForm.ergo_lab_wfh_saftey_message')\n                    "}],staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("updateClientForm.ergo_lab_wfh_saftey_message"))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Summary Report Footer")]),_c('ckeditor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"editor":_vm.editor,"data-vv-as":"summary report footer","config":_vm.editorConfig,"name":"ergo_lab_summary_report_footer","id":"ergo_lab_summary_report_footer"},on:{"blur":function($event){return _vm.onLostFocusEditor(
                        'updateClientForm.ergo_lab_summary_report_footer'
                      )}},model:{value:(_vm.client_data.ergo_lab_summary_report_footer),callback:function ($$v) {_vm.$set(_vm.client_data, "ergo_lab_summary_report_footer", $$v)},expression:"client_data.ergo_lab_summary_report_footer"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.errors.has(
                        'updateClientForm.ergo_lab_summary_report_footer'
                      )
                    ),expression:"\n                      errors.has(\n                        'updateClientForm.ergo_lab_summary_report_footer'\n                      )\n                    "}],staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "updateClientForm.ergo_lab_summary_report_footer" ))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"site_footer"},[_c('div',{staticClass:"client_btn_group action_btn"},[_c('div',{staticClass:"row mt_24 justify-content-start"},[_c('div',{staticClass:"col-md-10 col-lg-6 col-xl-4 text-start"},[_c('button',{staticClass:"btn btn_primary",attrs:{"type":"submit"}},[_vm._v(" Update ")]),_c('router-link',{attrs:{"to":{ name: 'all_clients' }}},[_c('button',{staticClass:"btn btn_outline ml_24",attrs:{"href":"javascript:void(0);"}},[_vm._v(" Cancel ")])])],1)])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-12 col-xxl-6"},[_c('div',{staticClass:"heading"},[_c('h2',[_vm._v("Update Client")]),_c('h3',[_vm._v(" PBE Management Dashboard / Update Client / Ergo Eval Lab ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_heading"},[_c('h4',{staticClass:"heading2"},[_vm._v("Ergo Eval Summary Report Content")])])
}]

export { render, staticRenderFns }